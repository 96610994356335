import React, { useCallback, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import * as z from 'zod';
import { AppContext } from '../App';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { useFormik } from 'formik';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Skeleton from '@material-ui/lab/Skeleton';
import { doQerkoRequest, doRequest, useQerkoMutation } from '../api/client';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		header: {
			// marginLeft: 10,
			fontSize: 12,
		},
		root: {
			width: '100%',
			maxWidth: 360,
			backgroundColor: theme.palette.background.paper,
		},
		paper: {
			// margin: 10,
		},
		slider: {
			margin: 30,
		},
		menuButton: {
			marginRight: theme.spacing(2),
		},
		title: {
			flexGrow: 1,
		},
	}),
);

const marks = [
	{
		value: 0,
		label: '0%',
	},
	{
		value: 25,
		label: '5%',
	},
	{
		value: 50,
		label: '10%',
	},
	{
		value: 75,
		label: '15%',
	},
	{
		value: 100,
		label: 'other',
	},
];

function valuetext(value: number) {
	return `${value}%`;
}

function valueLabelFormat(value: number) {
	return marks.findIndex((mark) => mark.value === value) + 1;
}

const billItemSchema = z.object({
	hash: z.string(),
	quantity: z.number(),
	pay: z.boolean(),
	price: z.number(),
	name: z.string(),
});

const billSchema = z.array(z.object({
	items: z.array(z.object({
		hash: z.string(),
		name: z.string(),
		price: z.string(),
		quantity: z.string(),
	}).nonstrict()),
	id: z.string(),
	currency: z.string(),
}).nonstrict());

interface BillItem {
	hash: string;
	quantity: number;
	pay: boolean;
	price: number;
	name: string;
}

type FormData = {
	billId: string,
	currency: string,
	cardId: string;
	items: BillItem[],
	tip: number,
}

type RequestData = FormData & {
	bill: ({
		hash: string;
		quantity: number;
	})[];
	pay: ({
		hash: string;
		quantity: number;
	})[];
	total: string;
	idDiscount: null;
	idCard: null;
	applePayPayloadData: unknown,
}

export const Table = () => {
	const classes = useStyles();
	const { auth, setAuth, apiUrl, selectedTable, setSelectedTable } = useContext(AppContext);
	const { enqueueSnackbar } = useSnackbar();
	const [dialogData, setDialogData] = useState<{ title: string; message: string, onClick: () => void } | null>(null);

	if (selectedTable === null) {
		throw new Error('context.selectedTable does not exist');
	}

	const { restaurantName, restaurantId, tableCode } = selectedTable;

	const [payMutation] = useQerkoMutation<RequestData>('POST', (auth, value) => `/api/v2/iip-client/bill/${restaurantId}/${encodeURIComponent(value.billId)}/pay`, z.undefined());
	const payBill = useCallback(async (values: FormData): Promise<Error | undefined> => {
		const total = Object.values(values.items).filter((item) => item.pay).reduce((acc, item) => item.price + acc, 0);

		const methodData = [
			{
				supportedMethods: 'https://apple.com/apple-pay',
				data: {
					version: 3,
					merchantIdentifier: 'merchant.com.qerko',
					merchantCapabilities: ["supports3DS", "supportsCredit", "supportsDebit"],
					supportedNetworks: ["amex", "discover", "masterCard", "visa"],
					countryCode: 'CZ',
				},
			}
		]

		const paymentDetails = {
			total: {
				label: restaurantName,
				amount: {value: `${total}`, currency: 'CZK'},
			},
		}

		try {
			const payRequest = new PaymentRequest(methodData, paymentDetails)
			// @ts-expect-error
			payRequest.onmerchantvalidation = async (event) => {
				const { result } = await doRequest(apiUrl, '/api/v2/iip-client/apple-pay/session', {
					validator: z.unknown(),
					method: 'POST',
					body: {
						validationURL: event.validationURL,
					}
				});
				event.complete(result);
			};
			const payResponse = await payRequest.show();

			enqueueSnackbar(`We're processing the payment with the restaurant. It can take some time.`, {
				variant: 'info',
			});

			try {
				await payMutation({
					...values,
					bill: Object.values(values.items).map((item) => ({
						hash: item.hash,
						quantity: item.quantity,
					})),
					pay: Object.values(values.items).filter((item) => item.pay).map((item) => ({
						hash: item.hash,
						quantity: item.quantity,
					})),
					tip: values.tip,
					total: `${total}`,
					idDiscount: null,
					idCard: null,
					applePayPayloadData: payResponse.toJSON(),
				});

				await payResponse.complete('success');
			} catch (e) {
				await payResponse.complete('fail');
				return e;
			}
		} catch (error) {
			return error;
		}
	}, [payMutation, enqueueSnackbar, apiUrl, restaurantName]);

	const { data, refetch: refetchItems } = useQuery<z.infer<typeof billSchema> | undefined, 'restaurantTableItems'>(
		'restaurantTableItems',
		async () => {
			const { statusCode, result: data } = await doQerkoRequest(auth, setAuth, apiUrl, () => `/api/v2/iip-client/table/${restaurantId}/${tableCode}/items`, {
				validator: billSchema,
			});

			if (statusCode === 503) {
				setDialogData({
					title: 'The restaurant is offline',
					message: `The restaurant is currently disconnected from Qerko. We can't serve your request.`,
					onClick: () => {
						setSelectedTable(null);
					},
				});
				return;
			}

			if (data.length === 0) {
				return [];
			}

			const items: z.infer<typeof billItemSchema>[] = [];
			data[0].items.forEach((item) => {
				items.push({
					pay: true,
					hash: item.hash,
					quantity: parseInt(item.quantity, 10),
					name: item.name,
					price: parseInt(item.price, 10),
				});
			});

			formik.setFieldValue('billId', data[0].id);
			formik.setFieldValue('currency', data[0].currency);
			formik.setFieldValue('items', items);

			return data;
		},
	);

	const formik = useFormik<FormData>({
		initialValues: {
			cardId: '',
			billId: '',
			currency: '',
			tip: 25,
			items: [],
		},
		onSubmit: async (values) => {
			const error = await payBill(values);
			if (error instanceof Error) {
				enqueueSnackbar(error.message, {
					variant: 'error',
				});
			} else {
				enqueueSnackbar(`The bill has been paid`, {
					variant: 'success',
				});
			}

			await refetchItems();
		},
	});

	let totalPrice = 0;

	return (
		<>
			<Dialog
				open={dialogData !== null}
				onClose={() => dialogData?.onClick()}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{dialogData?.title}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{dialogData?.message}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => dialogData?.onClick()} color="primary" autoFocus>
						OK
					</Button>
				</DialogActions>
			</Dialog>

			<AppBar position="static">
				<Toolbar>
					<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu"
								onClick={() => setSelectedTable(null)}>
						<ArrowBackIcon/>
					</IconButton>
					<Typography variant="h6" className={classes.title}>
						{restaurantName}
					</Typography>
					<IconButton edge="end" className={classes.menuButton} color="inherit" aria-label="menu"
								onClick={() => refetchItems()}>
						<RefreshIcon/>
					</IconButton>
				</Toolbar>
			</AppBar>

			<form noValidate onSubmit={formik.handleSubmit}>
				<Typography variant={'h6'} className={classes.header}>Orders</Typography>
				<Paper variant="outlined" className={classes.paper}>
					<List dense className={classes.root}>

						{data === undefined ? (
							<Skeleton animation={'wave'} variant="text"/>
						) : (
							<>
								{data.length === 0 ? (
									<>
										<p><strong>Please wait until the waiter enters your order</strong></p>
										<p>At any time, you can pay entire bill or just your part of it and leave.</p>
									</>
								) : (
									formik.values.items.map((item, key) => {
										const labelId = `checkbox-list-secondary-label-${item.hash}`;
										totalPrice += item.pay ? item.price : 0;
										return (
											<ListItem key={key} button>
												{/*<ListItemAvatar>*/}
												{/*    <Avatar*/}
												{/*        alt={`Avatar n°${value + 1}`}*/}
												{/*        src={`/static/images/avatar/${value + 1}.jpg`}*/}
												{/*    />*/}
												{/*</ListItemAvatar>*/}
												<ListItemText id={labelId} primary={`Line item ${item.name}`}/>
												<ListItemText id={labelId}
															  primary={`${item.price} ${data[0].currency}`}/>
												<ListItemSecondaryAction>
													<Checkbox
														edge="end"
														inputProps={{ 'aria-labelledby': labelId }}
														checked={item.pay}
														onChange={() => formik.setFieldValue(`items.${key}.pay`, !item.pay)}
													/>
												</ListItemSecondaryAction>
											</ListItem>
										);
									})
								)}
							</>
						)}

					</List>
				</Paper>

				<Typography variant={'h6'} className={classes.header}>Tip</Typography>
				<Paper variant="outlined" className={classes.paper}>
					<div className={classes.slider}>
						<Slider
							value={formik.values.tip}
							onChange={(_, value) => formik.setFieldValue(`tip`, value)}
							valueLabelFormat={valueLabelFormat}
							getAriaValueText={valuetext}
							aria-labelledby="discrete-slider-restrict"
							step={null}
							valueLabelDisplay="auto"
							marks={marks}
						/>
					</div>
				</Paper>

				{data !== undefined && (
					<Button
						variant="contained"
						color="primary"
						type="submit"
					>
						Pay {Math.round(totalPrice * (1 + (20 * formik.values.tip / 10000)))} {data.length === 0 ? 'CZK' : data[0].currency}
						{formik.isSubmitting && (
							<CircularProgress
								color={'inherit'}
								size={20}
								style={{ marginLeft: 20 }}
							/>
						)}
					</Button>
				)}
			</form>
		</>
	);
};
