import React, { useCallback, useContext } from 'react';
import QrReader from 'react-qr-reader';
import { AppContext } from '../App';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

interface ResponseData {
	restaurant: {
		id: string;
		name: string;
	};
	table: {
		code: string;
	};
}

export const ScannerPage = () => {
	const { apiUrl, setSelectedTable } = useContext(AppContext);

	const scanHandler = useCallback(async (data) => {
		if (typeof data !== 'string') {
			return;
		}

		const dataParts = data.split('/');

		const response = await fetch(`${apiUrl}/api/v2/iip-client/table-tag/${dataParts[dataParts.length - 1]}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		});

		const responseData: ResponseData = await response.json();

		setSelectedTable({
			restaurantId: responseData.restaurant.id,
			restaurantName: responseData.restaurant.name,
			tableCode: responseData.table.code,
		});

	}, [apiUrl, setSelectedTable]);

	const errorHandler = useCallback((err) => {
		console.error(err);
	}, []);

	return (
		<>
			<List component="nav" aria-label="mailbox folders">
				<ListItem>
					<ListItemText primary={(<Typography variant={'h6'}>Scan the QR code on the table</Typography>)}/>
				</ListItem>
			</List>

			<Typography variant={'body2'}>
				Scan QR code on the table to see your orders.<br/>
				Using the app you can pay entire bill or just your part of it at any time.
			</Typography>

			<QrReader
				delay={300}
				onError={errorHandler}
				onScan={scanHandler}
				resolution={320}
				style={{
					height: 320,
					width: 320,
				}}
			/>
		</>
	);
};
