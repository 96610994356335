import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import common from "@material-ui/core/colors/common";

const theme = createMuiTheme({
	typography: {
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
	},
	palette: {
		primary: {
			main: '#00b588',
			contrastText: common.white,
		},
	},
});

export default theme
