import React from "react";
import Box from "@material-ui/core/Box";
import Link from "./Link";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(
	({palette}) => ({
		link: {
			textDecoration: 'underline',
			color: palette.common.white,
		}
	})
)

const AppBanner = () => {
	const classes = useStyles()
	return (
		<Box bgcolor={'primary.main'} color={'common.white'} py={1} px={3}>
			<>{'Všechny účtenky přehledně v mobilu?'}</>
			<Link to={'/app'} className={classes.link}>{'Instalovat aplikaci'}</Link>
		</Box>
	);
}

export default AppBanner
