import React, { useContext } from 'react';
import {
	BrowserRouter,
	Switch,
	Route,
	Redirect, useLocation,
} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/core/styles';
import {TablePage} from './pages/TablePage';
import CssBaseline from '@material-ui/core/CssBaseline';
import {useLocalStorage} from '@rehooks/local-storage';
import {ScannerPage} from './pages/ScannerPage';
import {SnackbarProvider} from 'notistack';
import {NotFoundPage} from './pages/NotFoundPage';
import {ApplePayPage} from './pages/ApplePayPage'
import theme from "./theme";
import { Navigation } from './Navigation';
import { ProfilePage } from './pages/ProfilePage';

interface SelectedTable {
	restaurantId: string;
	restaurantName: string;
	tableCode: string;
}

export interface Auth {
	accessToken: string,
	userId: string,
	email: string;
	password: string;
}

interface AppContext {
	auth: Auth | null,
	setAuth: (values: Auth | null) => void;
	apiUrl: string;
	selectedTable: SelectedTable | null;
	setSelectedTable: (values: SelectedTable | null) => void;
}

const apiUrl = process.env.REACT_APP_API_URL !== undefined
	? process.env.REACT_APP_API_URL
	: 'https://sandbox.qerko.com';

export const AppContext = React.createContext<AppContext>({
	auth: null,
	setAuth: () => undefined,
	selectedTable: null,
	setSelectedTable: () => undefined,
	apiUrl,
});

const Page = ({children}: { children: React.ReactNode }) => (
	<div style={{position: 'relative', width: '100%'}}>
		{children}
	</div>
);

const Routes = () => {
	const {selectedTable} = useContext(AppContext);
	const location = useLocation();

	return (
		<>
			<CssBaseline/>
			<Switch location={location}>
				<Route exact path="/">
					<Page>
						{selectedTable === null ? <ScannerPage/> : <Redirect to={'/table'}/>}
					</Page>
				</Route>
				<Route exact path="/table">
					<Page>
						{selectedTable !== null ? <TablePage/> : <Redirect to={'/'}/>}
					</Page>
				</Route>
				<Route exact path="/profile">
					<Page>
						<ProfilePage/>
					</Page>
				</Route>
				<Route exact path="/apple-pay">
					<Page>
						<ApplePayPage/>
					</Page>
				</Route>
				<Route path="*">
					<NotFoundPage/>
				</Route>
			</Switch>
		</>
	);
};

export const App = () => {
	const [auth, setAuth] = useLocalStorage<Auth | null>('auth', null);
	const [selectedTable, setSelectedTable] = useLocalStorage<SelectedTable | null>('selectedTable', null);

	return (
		<BrowserRouter>
			<AppContext.Provider value={{
				auth,
				setAuth,
				selectedTable,
				setSelectedTable,
				apiUrl,
			}}>
				<ThemeProvider theme={theme}>
					<SnackbarProvider maxSnack={3}>
						<Routes/>
						<Navigation />
					</SnackbarProvider>
				</ThemeProvider>
			</AppContext.Provider>
		</BrowserRouter>
	);
};
