import { useContext } from 'react';
import { AppContext, Auth } from '../App';
import { MutationResultPair, QueryResult, useMutation, useQuery } from 'react-query';
import * as z from 'zod';
import { v4 as uuid } from 'uuid';

interface Response<TData> {
	statusCode: number;
	result: TData;
}

interface RequestOptions<TValidator, TBody extends Record<string, unknown> | undefined = undefined> {
	validator?: TValidator;
	method?: 'GET' | 'POST';
	body?: TBody;
	headers?: Record<string, string>;
}

export const doRequest = async <TValidator extends z.ZodTypeAny = z.ZodUndefined, TBody extends Record<string, unknown> | undefined = undefined>(apiUrl: string, path: string, { method = 'GET', validator, body, headers = {} }: RequestOptions<TValidator, TBody> = { method: 'GET', headers: {} }): Promise<Response<z.infer<TValidator>>> => {
	const response = await fetch(`${apiUrl}${path}`, {
		method,
		body: body !== undefined ? JSON.stringify(body) : undefined,
		headers: {
			'Content-Type': 'application/json',
			...headers,
		},
	});

	if (validator === undefined) {
		return {
			statusCode: response.status,
			result: undefined,
		};
	}

	const data = await response.json();

	if (validator instanceof z.ZodObject) {
		return {
			statusCode: response.status,
			result: await validator.nonstrict().parseAsync(data),
		};
	}

	return {
		statusCode: response.status,
		result: await validator.parseAsync(data),
	};
};

export const doQerkoRequest = async <TValidator extends z.ZodTypeAny = z.ZodUndefined, TBody extends Record<string, unknown> | undefined = undefined>(
	auth: Auth | null,
	setAuth: (auth: Auth) => unknown,
	apiUrl: string,
	path: (auth: Auth | null, values: TBody) => string | null,
	{
		method = 'GET',
		validator,
		body,
	}: RequestOptions<TValidator, TBody>): Promise<Response<z.infer<TValidator>>> => {
	let authData = auth;

	for (let x = 0; x < 2; x++) {
		const headers: Record<string, string> = {};
		if (authData !== null) {
			headers['Authorization'] = `Bearer ${authData.accessToken}`;
		}

		const url = path(authData, body as TBody);
		if (url !== null) {
			const { result, statusCode } = await doRequest(apiUrl, url, {
				method,
				headers,
				validator,
				body,
			});

			if (statusCode !== 401) {
				return {
					statusCode,
					result,
				};
			}
		}

		if (authData === null) {
			const values = {
				birthYear: 1990,
				email: `anonymous+${uuid()}@company.com`,
				name: 'anonym',
				surname: 'anonym',
				preferredCurrency: 'CZK',
				password: uuid(),
				phone: '606647814',
			};
			await doRequest(apiUrl, '/api/v2/iip-client/user', {
				method: 'POST',
				headers,
				body: values,
			});
			const { result: response } = await doRequest(apiUrl, '/api/v2/iip-client/access-token', {
				method: 'POST',
				headers,
				body: { ...values, device: 'web-client' },
				validator: z.object({
					id: z.string(),
					idUser: z.string(),
				}),
			});
			authData = {
				accessToken: response.id,
				userId: response.idUser,
				email: values.password,
				password: values.password,
			}
		} else {
			const { result: response } = await doRequest(apiUrl, '/api/v2/iip-client/access-token', {
				method: 'POST',
				headers,
				body: {
					email: authData.email,
					password: authData.password,
					device: 'web-client',
				},
				validator: z.object({
					id: z.string(),
					idUser: z.string(),
				}),
			});
			authData = {
				accessToken: response.id,
				userId: response.idUser,
				email: authData.email,
				password: authData.password,
			};
		}
		setAuth(authData);
	}

	throw new Error(`Unauthorized request`);
};

export const useQerkoQuery = <TValidator extends z.ZodType<any, any>, TKey extends string, TResult extends z.infer<TValidator>>(key: TKey, method: 'GET' | 'POST', path: (auth: Auth | null) => string | null, validator: TValidator): QueryResult<TResult> => {
	const { apiUrl, auth, setAuth } = useContext(AppContext);

	return useQuery<TResult, TKey>(
		key,
		async () => {
			const result = await doQerkoRequest<TValidator>(auth, setAuth, apiUrl, path, {
				method,
				validator,
			});

			return result.result;
		},
	);
};

export const useQerkoMutation = <TVariables extends Record<string, unknown> | undefined = undefined, TValidator extends z.ZodType<any, any> = z.ZodUndefined, TResult extends z.infer<TValidator> = undefined>(method: 'GET' | 'POST', path: (auth: Auth | null, values: TVariables) => string | null, validator: TValidator): MutationResultPair<TResult, TVariables, Error> => {
	const { apiUrl, auth, setAuth } = useContext(AppContext);
	return useMutation<TResult, TVariables, Error>(
		async (body: TVariables) => {
			const result = await doQerkoRequest<TValidator, TVariables>(auth, setAuth, apiUrl, path, {
				method,
				validator,
				body,
			});

			return result.result;
		},
	);
};
