import React from 'react';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useHistory } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InboxIcon from '@material-ui/icons/Inbox';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Skeleton from '@material-ui/lab/Skeleton';
import { useQerkoQuery } from '../api/client';
import * as z from 'zod';

const dataSchema = z.object({
	name: z.string(),
	surname: z.string(),
	email: z.string(),
	phone: z.string().optional()
});

export const ProfilePage = () => {
	const history = useHistory();

	const { data } = useQerkoQuery(
		'userInfo',
		'GET',
		(auth) => auth !== null ? `/api/v1/iip-client/user/${auth.userId}` : null,
		dataSchema,
	);

	return (
		<>
			<List component="nav" aria-label="mailbox folders">
				<ListItem>
					<ListItemText primary={(<Typography variant={'h6'}>Profile</Typography>)}/>
				</ListItem>
			</List>

			<List>
				<ListItem button>
					<ListItemAvatar>
						<AccountCircleIcon style={{ fontSize: 40 }}/>
					</ListItemAvatar>
					<ListItemText
						primary={data !== undefined ? `${data.name} ${data.surname}` :
							<Skeleton animation={'wave'} variant="text"/>}
						secondary={data !== undefined ? (
							<>
								{data.email}<br/>
								{data.phone}
							</>
						) : <><Skeleton animation={'wave'} variant="text"/><Skeleton animation={'wave'}
																					 variant="text"/></>}
					/>
				</ListItem>

				<ListSubheader>Restaurant receipts</ListSubheader>

				<List component="nav" aria-label="mailbox folders">
					<Divider/>
					<ListItem button onClick={() => history.push(`/profile/payment-methods`)}>
						<ListItemIcon>
							<InboxIcon/>
						</ListItemIcon>
						<ListItemText primary="Payment methods"/>
						<ListItemIcon>
							<ArrowForwardIosIcon/>
						</ListItemIcon>
					</ListItem>
					<Divider/>
					<ListItem button onClick={() => history.push(`/profile/payment-methods`)}>
						<ListItemText primary="All receipts"/>
						<ListItemIcon>
							<ArrowForwardIosIcon/>
						</ListItemIcon>
					</ListItem>
					<Divider/>
				</List>

				<ListSubheader>Help and support</ListSubheader>

				<List component="nav" aria-label="mailbox folders">
					<Divider/>
					<ListItem button onClick={() => history.push(`/profile/payment-methods`)}>
						<ListItemText primary="FAQ"/>
						<ListItemIcon>
							<ArrowForwardIosIcon/>
						</ListItemIcon>
					</ListItem>
					<Divider/>
					<ListItem button onClick={() => history.push(`/profile/payment-methods`)}>
						<ListItemText primary="Get support"/>
						<ListItemIcon>
							<ArrowForwardIosIcon/>
						</ListItemIcon>
					</ListItem>
					<Divider/>
				</List>
			</List>
		</>
	);
};
