import React, { useCallback, useContext, useState } from 'react';
import { doRequest } from '../api/client';
import { AppContext } from '../App';
import * as z from 'zod';

export const ApplePayPage = () => {
	const { apiUrl } = useContext(AppContext);
	const [log, setLog] = useState('')
	const handleApplePayClick = useCallback(
		async () => {
			const methodData = [
				{
					supportedMethods: 'https://apple.com/apple-pay',
					data: {
						version: 3,
						merchantIdentifier: 'merchant.com.qerko',
						merchantCapabilities: ["supports3DS", "supportsCredit", "supportsDebit"],
						supportedNetworks: ["amex", "discover", "masterCard", "visa"],
						countryCode: 'CZ',
					},
				}
			]

			const paymentDetails = {
				id: "test-kolkovna-2",
				total: {
					label: 'Test Kolkovna Dock 1',
					amount: {value: '1', currency: 'CZK'},
				},
				displayItems: [{
					label: "Test Kolkovna Dock 1",
					amount: { value: "1", currency: "CZK" },
				}],
			}
			try {
				const payRequest = new PaymentRequest(methodData, paymentDetails)
				// @ts-expect-error
				payRequest.onmerchantvalidation = async (event) => {
					setLog((state) =>
						`validationURL:\n${event.validationURL}\n\n`
					)

					const { result } = await doRequest(apiUrl, '/api/v2/iip-client/apple-pay/session', {
						validator: z.unknown(),
						method: 'POST',
						body: {
							validationURL: event.validationURL,
						}
					});

					setLog((state) =>
						`validationResponse:\n${JSON.stringify(result)}\n\n`
					)

					event.complete(result);
				};

				setLog((state) =>
					`PayRequest:\n${JSON.stringify(payRequest, null, 2)}\nRAW:${payRequest}\n\n` + state
				)

				const canMakePayment = await payRequest.canMakePayment()
				setLog((state) =>
					`canMakePayment:\n${canMakePayment}\n\n` + state
				)

				const payResponse = await payRequest.show()
				setLog((state) =>
					`PayResponse:\n${payResponse.toJSON()}\nRAW:${payResponse}\n\n` + state
				)
				await payResponse.complete('success');
			} catch (error) {
				setLog(
					(state) =>
						`Error:\n${JSON.stringify(error, null, 2)}\nRAW:${error}\n\n` + state
				)
			}
		},
		[apiUrl]
	)

	return (
		<>
			<button
				type={'button'}
				onClick={handleApplePayClick}
				style={{
					// @ts-ignore
					'-webkit-appearance': '-apple-pay-button',
					'-apple-pay-button-type': 'plain',
					'-apple-pay-button-style': 'black'
				}}
				lang="en"
			/>
			<pre>{log}</pre>
		</>
	)
}
