import React from 'react';
import { Table } from '../components/Table';
import AppBanner from "../components/AppBanner";

export const TablePage = () => {
	return (
		<>
			<AppBanner />
			<Table/>
		</>
	);
};
