import React, { useContext } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import ContactlessIcon from '@material-ui/icons/Contactless';
import PersonIcon from '@material-ui/icons/Person';
import { Link, useLocation } from 'react-router-dom';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { AppContext } from './App';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	appBar: {
		top: 'auto',
		bottom: 0,
	},
}));

const navigationLinks: Record<string, number> = {
	'/': 0,
	'/profile': 1,
};

export const Navigation = () => {
	const classes = useStyles();
	const { selectedTable } = useContext(AppContext);
	const location = useLocation();
	const [value, setValue] = React.useState(() => {
		const pathName = '/' + location.pathname.split('/').slice(1, 2);
		console.log('pathName', pathName);
		if (navigationLinks[pathName] === undefined) {
			return 0;
		}

		return navigationLinks[pathName];
	});

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue);
	};

	return (
		<div className={classes.root}>
			<AppBar position="fixed" color="default" className={classes.appBar}>
				<BottomNavigation
					value={value}
					onChange={(event, newValue) => {
						handleChange(event, newValue);
					}}
					showLabels
				>
					<BottomNavigationAction
						label="Payment"
						component={Link}
						to={selectedTable !== null ? '/table' : '/'}
						icon={<ContactlessIcon/>}
					/>
					<BottomNavigationAction
						label="Profile"
						component={Link}
						to={'/profile'}
						icon={<PersonIcon/>}
					/>
					<BottomNavigationAction
						label="Pay"
						component={Link}
						to={'/apple-pay'}
						icon={<PersonIcon/>}
					/>
				</BottomNavigation>
			</AppBar>
		</div>
	);
};
